var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { value: _vm.value, persistent: "", width: "1200" }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v("Shuttle Items to Ad")])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-6" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          readonly: "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          label: "Ad Date",
                          "prepend-inner-icon": "mdi-calendar",
                          value: _vm.formattedAdDate,
                          autocomplete: "off"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          autofocus: "",
                          "auto-select-first": "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          items: _vm.adTypes,
                          label: "Ad Type",
                          "item-text": "description",
                          "item-value": "id",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.adType,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "adType", $$v)
                          },
                          expression: "shuttle.adType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          items: _vm.pages,
                          label: "Page",
                          "item-text": "description",
                          "item-value": "id",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.adPage,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "adPage", $$v)
                          },
                          expression: "shuttle.adPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "auto-select-first": "",
                          "background-color": "#fff",
                          dense: "",
                          outlined: "",
                          items: _vm.featureTypes,
                          label: "Feature Type",
                          "item-text": "description",
                          "item-value": "id",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.featureType,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featureType", $$v)
                          },
                          expression: "shuttle.featureType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Brand / Combo",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.featDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featDesc", $$v)
                          },
                          expression: "shuttle.featDesc"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "More Text",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.featDesc2,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featDesc2", $$v)
                          },
                          expression: "shuttle.featDesc2"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Selected Varieties",
                          autocomplete: "off",
                          tabindex: "-1"
                        },
                        model: {
                          value: _vm.shuttle.featDesc3,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featDesc3", $$v)
                          },
                          expression: "shuttle.featDesc3"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Dimensions",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.shuttle.featDesc4,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featDesc4", $$v)
                          },
                          expression: "shuttle.featDesc4"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          "background-color": "#fff",
                          label: "Feature Notes"
                        },
                        model: {
                          value: _vm.shuttle.featNotes,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "featNotes", $$v)
                          },
                          expression: "shuttle.featNotes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Ad Retail Qty",
                          placeholder: "0",
                          type: "number",
                          autocomplete: "off",
                          required: ""
                        },
                        model: {
                          value: _vm.shuttle.retCt,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "retCt", $$v)
                          },
                          expression: "shuttle.retCt"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          "background-color": "#fff",
                          outlined: "",
                          label: "Ad Retail",
                          type: "number",
                          prefix: "$",
                          autocomplete: "off",
                          required: ""
                        },
                        on: {
                          change: _vm.getSRPList,
                          blur: function($event) {
                            return _vm.formatCurrencyInput(
                              "retail",
                              _vm.shuttle.retail
                            )
                          }
                        },
                        model: {
                          value: _vm.shuttle.retail,
                          callback: function($$v) {
                            _vm.$set(_vm.shuttle, "retail", $$v)
                          },
                          expression: "shuttle.retail"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "1", align: "right" } })
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            staticClass: "table",
            attrs: {
              headers: _vm.headers,
              height: _vm.tableSize,
              "items-per-page": _vm.pageSize,
              items: _vm.adGroups,
              dense: "",
              "fixed-header": "",
              "hide-default-footer": "",
              "show-select": "",
              "item-key": "ad_group.id",
              search: _vm.search,
              "no-data-text":
                "Complete Feature Information to Display Ad Groups"
            },
            on: {
              "update:items": function($event) {
                _vm.adGroups = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.ad_retail",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.ad_retail_count) +
                            " @ $" +
                            _vm._s(_vm.displayFormat(item.ad_retail))
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.gp_pct",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.displayFormat(item.gp_pct)) + "%")
                      ])
                    ]
                  }
                },
                {
                  key: "item.supplier_code",
                  fn: function(ref) {
                    return undefined
                  }
                },
                {
                  key: "item.ad_fee",
                  fn: function(props) {
                    return [
                      _c(
                        "v-edit-dialog",
                        {
                          attrs: { "return-value": props.item.ad_fee },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(props.item, "ad_fee", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(props.item, "ad_fee", $event)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        prefix: "$",
                                        label: "Ad Fee",
                                        "single-line": ""
                                      },
                                      model: {
                                        value: props.item.ad_fee,
                                        callback: function($$v) {
                                          _vm.$set(props.item, "ad_fee", $$v)
                                        },
                                        expression: "props.item.ad_fee"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.displayFormat(props.item.ad_fee)) +
                              " "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("v-divider"),
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "footer",
                          attrs: { flat: "", color: "#fafafa" }
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "2", align: "center" } },
                            [
                              _vm.selected.length
                                ? _c("span", { staticClass: "blue--text" }, [
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(_vm.selected.length) +
                                          " Selected"
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                disabled: !_vm.selected.length,
                                loading: _vm.loading,
                                color: "#00B24A"
                              },
                              on: { click: _vm.shuttleToAd }
                            },
                            [
                              _vm._v(" Shuttle to Ad"),
                              _c(
                                "v-icon",
                                { staticClass: "ml-1", attrs: { dense: "" } },
                                [_vm._v("mdi-transfer-right")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      ),
      _c("ShuttleAlert", {
        attrs: { value: _vm.shuttleAlert },
        on: {
          "update:value": function($event) {
            _vm.shuttleAlert = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }