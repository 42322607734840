<template>
  <v-dialog :value="value" class="dialog" persistent width="1200">
    <v-card color=#fafafa>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Shuttle Items to Ad</v-toolbar-title>
      </v-toolbar>

      <v-container class="ma-0 pa-6">
          <v-row dense>
            <v-col cols="2">
              <v-text-field readonly background-color="#fff" dense outlined label="Ad Date"
                prepend-inner-icon="mdi-calendar" :value="formattedAdDate" autocomplete="off">
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-autocomplete autofocus auto-select-first background-color="#fff" dense outlined :items="adTypes"
                label="Ad Type" item-text="description" item-value="id" v-model="shuttle.adType" autocomplete="off">
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete auto-select-first background-color="#fff" dense outlined :items="pages" label="Page"
                item-text="description" item-value="id" v-model="shuttle.adPage" autocomplete="off">
              </v-autocomplete>
            </v-col>
            <v-col cols="5">
              <v-autocomplete auto-select-first background-color="#fff" dense outlined :items="featureTypes"
                label="Feature Type" item-text="description" item-value="id" v-model="shuttle.featureType"
                autocomplete="off">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field dense background-color="#fff" outlined label="Brand / Combo" v-model="shuttle.featDesc"
                autocomplete="off">
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field dense background-color="#fff" outlined label="More Text" v-model="shuttle.featDesc2"
                autocomplete="off">
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field dense background-color="#fff" outlined label="Selected Varieties"
                v-model="shuttle.featDesc3" autocomplete="off" tabindex="-1">
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field dense background-color="#fff" outlined label="Dimensions" v-model="shuttle.featDesc4"
                autocomplete="off">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="8">
              <v-text-field outlined dense background-color="#fff" label="Feature Notes"
                v-model="shuttle.featNotes">
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field 
                dense 
                background-color="#fff" 
                outlined label="Ad Retail Qty" 
                v-model="shuttle.retCt"
                placeholder="0"
                type="number" 
                autocomplete="off"
                required
                >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field dense background-color="#fff" outlined label="Ad Retail" type="number" prefix="$"
                v-model="shuttle.retail" @change="getSRPList" @blur="formatCurrencyInput('retail', shuttle.retail)" autocomplete="off" required>
              </v-text-field>
            </v-col>
            <v-col cols="1" align="right">
            </v-col>
          </v-row>
      </v-container>

      <v-divider/>

      <v-data-table :headers="headers" :height="tableSize" :items-per-page="pageSize" :items.sync="adGroups"
        class="table" dense fixed-header hide-default-footer show-select v-model="selected" item-key="ad_group.id" :search="search"
        no-data-text="Complete Feature Information to Display Ad Groups">

        <!-- <template v-slot:top>
          <v-toolbar flat color="#fafafa" class="pt-3">
            <v-col cols="4">
              <v-autocomplete :disabled="!suppliers.length" outlined dense background-color="#fff" :items="suppliers" item-text="description" item-value="id"
                prepend-inner-icon="mdi-filter-outline" label="Filter By Division" v-model="search" clearable :menu-props="{ maxHeight: 260 }">
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.description}}</v-list-item-title>
                      <v-list-item-title>
                        <span class="primary--text">{{getShuttleGroupsCount(data.item.id)}} / {{data.item.groups.length}} Groups Shuttled</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-toolbar>
          <v-divider/>
        </template> -->

        <template v-slot:[`item.ad_retail`]="{ item }">
          <span>{{item.ad_retail_count}} @ ${{displayFormat(item.ad_retail)}}</span>
        </template>

        <template v-slot:[`item.gp_pct`]="{ item }">
          <span>{{displayFormat(item.gp_pct)}}%</span>
        </template>

        <template v-slot:[`item.supplier_code`]="{}">
        </template>

        <template v-slot:[`item.ad_fee`]="props">
          <v-edit-dialog :return-value.sync="props.item.ad_fee">
            {{displayFormat(props.item.ad_fee)}}
            <template v-slot:input>
              <v-text-field v-model="props.item.ad_fee" type="number" prefix="$" label="Ad Fee" single-line>
              </v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:footer>
          <v-divider />
          <v-toolbar flat color="#fafafa" class="footer">
            <v-spacer />
            <v-col cols="2" align="center">
              <span v-if="selected.length" class="blue--text">
                <i>{{selected.length}} Selected</i>
              </span>
            </v-col>
            <v-spacer />
              <v-btn :disabled="!selected.length" :loading="loading" color="#00B24A" class="white--text" @click="shuttleToAd">
                Shuttle to Ad<v-icon class="ml-1" dense>mdi-transfer-right</v-icon>
              </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>

    <ShuttleAlert :value.sync="shuttleAlert" />

  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'
import { cloneDeep } from 'lodash'

export default {
  name: 'ShuttleToAd',

  components: {
    ShuttleAlert: () => import('@/components/contracts/shuttle-alert')
  },

  mixins: [tableSettings, notification],

  props: ['value', 'contract'],

  data() {
    return {
      adGroups: [],
      adRetail: '',
      adRetailCount: '',
      adTypes: [],
      featureTypes: [],
      loading: false,
      pages: [],
      pageSize: 100,
      search: '',
      selected: [],
      showCurrent: false,
      shuttle: {
        retCt: 0
      },
      shuttleAlert: false,
      shuttledGroups: [],
      shuttling: false,
      sliderSize: 3,
      suppliers: [],
      consentinoGroups: [
        'CPC',
        'CPG',
        'CMA'
      ]
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Ad Group', align: 'left', sortable: 'true', filterable: 'true', value: 'ad_group.name', class: 'black--text' },
        { text: 'Ad Retail', align: 'left', sortable: 'true', filterable: 'true', value: 'ad_retail', class: 'black--text' },
        { text: 'GP %', align: 'left', sortable: 'true', filterable: 'true', value: 'gp_pct', class: 'black--text' },
        { text: 'Ad Fee', align: 'left', sortable: 'true', filterable: 'true', value: 'ad_fee', class: 'black--text' },
        { value: 'supplier_code' }
      ]
    },

    selectedItem () {
      return cloneDeep(this.contract.ContractItems[0])
    },

    formattedAdDate () {
      return this.contract.AdDate ? this.moment(this.contract.AdDate).format(this.$config.date_display) : ''
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.shuttle = {}
          this.shuttle.featDesc3 = 'Selected Varieties'
          this.selected = []
          this.adGroups = []
          this.getShuttleOptions()
          this.setShipDates(this.contract.AdDate)
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 475
  },

  methods: {
    async getShuttleOptions () {
      const featureTypes = this.$ads.getAdFeatureTypes()
      const adTypes = this.$ads.getAdTypes()
      const pages = this.$ads.getPages()

      await Promise.all([featureTypes, adTypes, pages])
        .then(response => {
          this.featureTypes = response[0]
          this.adTypes = response[1]
          this.pages = response[2]
        })
    },

    getShuttleGroupsCount (supplierCode) {
      return this.shuttledGroups.filter(adGroup => adGroup.supplier_code === supplierCode).length
    },

    formatCurrencyInput (key, value) {
      this.shuttle[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    displayFormat (value) {
      return ((value * 100) / 100).toFixed(2)
    },

    setShipDates (date) {
      this.shuttle.firstShipDate = this.moment(date).subtract(7, 'days').format(this.$config.date_format)
      this.shuttle.secondShipDate = date
    },

    async getSRPList () {
      this.loading = true
      this.selectedItem.Item.SRPCount = this.shuttle.retCt
      this.selectedItem.Item.SRP = this.shuttle.retail
      let isCosentinoContract = this.consentinoGroups.find(x => x === this.contract.AdGroup.ID)
     await this.$shuttles.getAdGroupSRPs(this.selectedItem)
        .then(response => {
          this.adGroups = []
          this.adGroups = response
          this.loading = false
        })
      this.adGroups = this.adGroups.filter(item => {
        let isCosentino = this.consentinoGroups.find(x => x === item.ad_group.id)
        if (isCosentinoContract) {
          return isCosentino
        }
        else {
          return !isCosentino
        }
      })

    },

    async getSuppliers () {
      await this.$contracts.getSuppliers()
        .then(response => {
          response.forEach(supplier => {
            supplier.groups = []
            supplier.id = supplier.id.toString()
            this.adGroups.forEach(adGroup => {
              if (adGroup.supplier_code === supplier.id) {
                supplier.groups.push(adGroup)
              }
            })
          })
          this.suppliers = response.filter(supplier => supplier.groups.length)
        })
    },

    getSupplierName (supplierCode) {
      this.suppliers.forEach(supplier => {
        if (supplierCode === supplier.id) {
          return supplier.description
        }
      })
    },

    updateGroups () {
      var updatedGroups = []
      this.selected.forEach(group => {
        if (Object.prototype.hasOwnProperty.call(this.update, 'adRetailCount')) {
          group.ad_retail_count = this.update.adRetailCount
        }
        if (Object.prototype.hasOwnProperty.call(this.update, 'adRetail')) {
          group.ad_retail = this.update.adRetail
        }
        if (Object.prototype.hasOwnProperty.call(this.update, 'adFee')) {
          group.ad_fee = this.update.adFee
        }
        var item = {
          AMAP: this.selectedItem.AMAP,
          AdBB: this.selectedItem.AdBB,
          AdGroupCode: group.ad_group.id,
          AdFee: group.ad_fee,
          AdItemContractID: this.selectedItem.AdItemContractID,
          AdScan: this.selectedItem.AdScan,
          Compete: this.selectedItem.Compete,
          ContractID: this.selectedItem.ContractID,
          DealID: this.selectedItem.DealID,
          EBA: this.selectedItem.EBA,
          Item: {
            CaseCost: this.selectedItem.Item.CaseCost,
            Description: this.selectedItem.Item.Description,
            ItemCode: this.selectedItem.Item.ItemCode,
            OrderCode: this.selectedItem.Item.OrderCode,
            Pack: this.selectedItem.Item.Pack,
            PromoGroup: this.selectedItem.Item.PromoGroup,
            SRP: group.ad_retail,
            SRPCount: group.ad_retail_count,
            Size: this.selectedItem.Item.Size,
            UPC: this.selectedItem.Item.UPC
          },
          PCAF: this.selectedItem.PCAF,
          TPRBB: this.selectedItem.TPRBB,
          TPRScan: this.selectedItem.TPRScan,
          ePayAd: this.selectedItem.ePayAd,
          ePayTPR: this.selectedItem.ePayTPR
        }
        updatedGroups.push(item)
      })
      updatedGroups.forEach(group => {
        this.$ads.getSRPList(group).then(response => {
          this.adGroups.forEach(adGroup => {
            if (adGroup.ad_group.id === response[0].ad_group.id) {
              adGroup.ad_retail_count = response[0].ad_retail_count
              adGroup.ad_retail = response[0].ad_retail
              adGroup.gp_pct = response[0].gp_pct
              adGroup.ad_fee = response[0].ad_fee
            }
          })
        })
      })
      if (this.contract.ContractScope !== 3) {
        this.selected = []
      }
      this.update = {}
      this.updateRetails = false
    },

    async shuttleToAd() {
      this.showLoader('Shuttling Groups', true)

      const selectedGroups = this.selected.map(group => {
        return {
          adGroupCode: group.ad_group.id,
          adFee: group.ad_fee
        }
      })

      const shuttle = {
        adDate: this.contract.AdDate,
        adGroups: selectedGroups,
        adPage: this.shuttle.adPage,
        adType: this.shuttle.adType,
        contractId: this.contract.ContractID,
        featDesc: this.shuttle.featDesc,
        featDesc2: this.shuttle.featDesc2,
        featDesc3: this.shuttle.featDesc3,
        featDesc4: this.shuttle.featDesc4,
        featNotes: this.shuttle.featNotes,
        featureType: this.shuttle.featureType,
        firstShipDate: this.shuttle.firstShipDate,
        retail: this.shuttle.retail,
        retCt: this.shuttle.retCt,
        secondShipDate: this.shuttle.secondShipDate
      }

      await this.$shuttles.shuttleToAd(shuttle)
        .then(() => {
            this.showLoader('Complete', false)
            this.shuttleAlert = true
            this.selected.filter(x => {
            var itemIndex = this.adGroups.indexOf(x)
            this.adGroups.splice(itemIndex, 1)
          })
          this.shuttling = false 
        }).catch(() => {
          this.notify('error', 'Error Submitting Shuttle')
        })
      this.selected = []  
    }
  }
}
</script>
